<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">
        If you pour
        <number-value :value="volume" unit="\text{mL}" />
        of a
        <number-value :value="conc" unit="\text{M}" />
        sodium chloride solution into a reaction vessel, how many moles of NaCl would be in the
        vessel?
      </p>

      <calculation-input
        v-model="inputs.mol"
        prepend-text="$\ce{NaCl:}$"
        append-text="$\text{mol}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';

export default {
  name: 'Question385',
  components: {
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        mol: null,
      },
    };
  },
  computed: {
    conc() {
      return this.taskState.getValueBySymbol('conc').content;
    },
    volume() {
      return this.taskState.getValueBySymbol('volume').content;
    },
  },
};
</script>
